
.feedback-report-buttons{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.feedback-question-table{
    .action-buttons{
        justify-content: flex-end;
    }
}