// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight>pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json>pre {
  background: none #fff !important;
}

.example-code>.json {
  background: none #fff !important;
}

.symbol.symbol-45>svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

#permission-container {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100px;
  overflow: hidden;
  overflow-y: auto;
}

#permission-container ul {
  padding-left: 5px;
}

#permission-container ul li label input {
  margin-right: 5px;
}

.optionContainer li input {
  margin-right: 5px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  width: 21.7em;
  padding: 5px;
  padding-left: 13px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.invalidclass {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #F64E60;
}

.errorphone {
  border-color: #F64E60 !important;
}
.errorMultiSelect {
  border: 1px solid #F64E60 !important;
  border-radius: 5px!important;
}
.buttonWidth{
  width: 150px !important;
}

.header {
  background-color: #f2f2f2;
}
.brand-dark .brand {
  background-color: #ffffff;
  box-shadow: none;
}


.MuiPopover-paper {
  max-width: 320px !important;
  min-width: 320px !important;
  .multi-select {
    max-width: 320px !important;
  }
}

.visibility-to{
  .valid-feedback{
    display: none;
  }
  .feedback{
    display: none;
  }
  .form-group>label:first-child{
    display: none;
  }
  .invalid{
    .dropdown-container{
      border-color: red;
    }
  }
}