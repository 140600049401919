.star-rating {
  display: flex;
  gap: 4px;
  font-size: 24px;
  .star {
    color: #f58220; /* Gold color */
    &.empty {
      color: #ccc; /* Gray for empty stars */
    }
    &.half {
      background: linear-gradient(90deg, #f58220 50%, #ccc 50%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
  }
  &.success {
    .star {
      color: #4caf50;
      &.half {
        background: linear-gradient(90deg, #4caf50 50%, #ccc 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
      }
    }
  }
}
