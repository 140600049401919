//
// Login 1
// Pages SASS files are compiled into separate css files
//


// Initialization of global variables, mixins and functions
@import "../../../init";
.login.login-1.login-signin-on .login-signin {
	background: $white;
	padding: 45px 50px 35px 50px;
	border-radius: 30px;
}
.recaptha-row > div {
    transform:scale(1.15);
    -webkit-transform:scale(1.15);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
}

.login.login-1 {
    // Form modes
    .login-signin,
    .login-signup,
    .login-forgot {
        display: none;
    }

    &.login-signin-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: block;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-signup-on {
        .login-signup {
            display: block;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-forgot-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: block;
        }
    }
}

// Desktop view
@include media-breakpoint-up(lg) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            max-width: 600px;
        }

        .login-form {
            width: 100%;
            max-width: 450px;
        }
    }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            max-width: 400px;
        }
    }
}

// Tablet mode
@include media-breakpoint-down(md) {
    .login.login-1 {
        .login-form {
            width: 100%;
            max-width: 450px;
        }
    }
}

// Mobile mode
@include media-breakpoint-down(xs) {
    .login.login-1 {
        &.login-signin-on {
            .login-signin {
                padding: 20px;
            }
        }
    }
}
