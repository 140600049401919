.questions-responses-container {
  .questions-responses-col {
    .responses-report {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      .level-count-row {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin: 5px 0;
        .line {
          flex: 1 1;
          height: 12px;
          width: 100%;
          margin: 0 15px;
          border-radius: 3px;
          &.filled {
            background-color: #f58220;
          }
        }
      }
    }
    .responses-average-rating {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      .average-rating {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        strong {
          &.success {
            color: #4caf50;
          }
          &.danger {
            color: #f58220;
          }
          font-size: 24px;
        }
      }
    }
    .responses-overall-rating {
      margin: 0 auto;
      max-width: 350px;
      background: #f58220;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 15px 5px;
      border-radius: 20px;
      .overall-rating {
        strong {
          font-weight: normal;
          font-size: 35px;
          color: #fff;
        }

        + p {
          color: #fff;
          font-size: 22px;
        }
      }
    }
  }
}
